.page-comparison {
  .badge {
    background: none;

    &:hover {
      background: var(--color-blue);
    }

    &--active {
      background: var(--color-dark-blue);
    }
  }

  .comparison-table {
    padding: 1rem;
  }

  .row {
    display: flex;
  }

  .cell {
    border: 0.125rem solid var(--color-red);
    width: 33%;
    display: flex;
    padding: 2rem;
    gap: 1rem;
    justify-content: center;
    border-left: none;
    border-right: none;

    &.align-center {
      border-left: 0.25rem solid var(--color-red);
      border-right: 0.25rem solid var(--color-red);
    }

    &.align-space-between {
      justify-content: space-between;
    }

    .label {
      color: var(--color-white);

      &.text-left {
        text-align: left;
      }

      &.text-right {
        text-align: right;
      }
    }

    .items-container {
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;
      width: 70%;

      &.justify-flex-end {
        justify-content: flex-end;
      }

      &.justify-flex-start {
        justify-content: flex-start;
      }
    }

    .cell-item {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.5rem;
    }
  }
}
