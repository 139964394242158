:root {
  --color-gray-dark: #475157;
  --color-gray-dark-alt: #4f585e;
  --color-gray-dark-alt2: #434d53;
  --color-gray-dark-alt3: #3a444a;
  --color-gray-dark-alt4: #2f393f;
  --color-gray-normal: #7f858a;
  --color-gray-light: #7d878d;
  --color-gray-light-alt: #adadad;

  --color-white: #fff;
  --color-green: #50C878;
  --color-orange: #FFA163;
  --color-blue: #3E6DCC;
  --color-dark-blue: #2E5299;
  --color-marine: #0E8B93;
  --color-black: #000000;
  --color-red: #F54562;
  --color-brand: #FF781F;
  --until-sm: 30em;
  --until-md: 48em;
  --until-lg: 61.875em;
  --until-xl: 75em;
}

// @custom-media --until-sm (max-width: 30em);
// @custom-media --until-md (max-width: 48em);
// @custom-media --until-lg (max-width: 61.875em);
// @custom-media --until-xl (max-width: 75em);

body {
  background: var(--color-gray-dark);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DIN";
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;

  & h1 {
    color: blue;
  }
}

#root {
  min-height: 100vh;
  overflow: hidden;
}

@import "./styles/main.scss";
