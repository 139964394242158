.badge {
  color: var(--color-white);
  display: inline-block;
  box-sizing: border-box;
  padding: 0 15px;
  text-transform: uppercase;
  border-radius: 13px;
  font-size: 12px;
  line-height: 25px;
  height: 25px;
  overflow: hidden;
  text-decoration: none;
  border: 1px solid var(--color-gray-normal);

  &--big {
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    margin-right: 5px;
    padding: 0 12px;
  }

  &--small {
    border-radius: 10px;
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    padding: 0 10px;
    margin: 0 0.5rem 0 0;
  }

  &--all {
    background: var(--color-gray-normal);
    border-color: var(--color-gray-normal);
  }
  &--adopt {
    background: var(--color-green);
    border-color: var(--color-green);
  }
  &--trial {
    background: var(--color-orange);
    border-color: var(--color-orange);
  }
  &--assess {
    background: var(--color-blue);
    border-color: var(--color-blue);
  }
  &--hold {
    background: var(--color-marine);
    border-color: var(--color-marine);
  }
  &--the-abyss {
    background: var(--color-black);
    border-color: var(--color-black);
  }
  &--active {
    background-color: var(--color-dark-blue);
    border-color: var(--color-blue);
  }
}
