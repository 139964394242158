.flag {
  font-size: 10px;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 10px;
  position: relative;
  vertical-align: top;
  margin-top: -2px;
  left: 5px;
  color: var(--color-white);

  &--new {
    background: var(--color-red);
  }

  &--changed {
    background: var(--color-blue);
  }

  &--new--long {
    background: var(--color-red);
    vertical-align: middle;
    display: initial;
  }
  &--changed--long {
    background: var(--color-blue);
    vertical-align: middle;
    display: initial;
  }
}
