// These styles utilize repeated class names in order to override the specificity from the default styles coming from `react-select`
.custom-react-select.custom-react-select {
  margin-bottom: 20px;
  width: 600px;
  color: var(--color-white);
  font-size: 16px;
}

.custom__multi-value.custom__multi-value {
  background-color: var(--color-gray-dark-alt);
  align-items: space-between;
  justify-content: space-between;
  color: var(--color-white);

  .custom__multi-value__label {
    color: var(--color-white);
  }
}

.custom__input-container.custom__input-container {
  color: var(--color-white);
}

.custom__placeholder.custom__placeholder {
  color: var(--color-gray-light-alt);
  opacity: 0.75;
}

.custom__value-container {
  .custom__single-value {
    color: var(--color-gray-light-alt);
    opacity: 0.75;
  }
}

.custom__indicators.custom__indicators {
  opacity: 0.75;
}

.custom__control.custom__control {
  min-height: 50px;
  background-color: var(--color-gray-dark-alt3);
  border-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border: 0;
  padding: 0 10px;

  &:hover {
    outline: none;
  }
}

.custom__control--is-focused.custom__control--is-focused.custom__control--is-focused {
  background-color: var(--color-gray-dark-alt4);
}

.custom__control--is-focused.custom__control--is-focused {
  background-color: var(--color-gray-dark-alt3);
}

.custom__menu.custom__menu {
  background-color: var(--color-gray-dark-alt2);
  border-radius: 0;
  margin: 1px;
  width: calc(100% - 2px);

  .custom__option {
    background-color: var(--color-gray-dark-alt2);
    color: var(--color-white);
    padding: 10px 18px;
  }

  div {
    :nth-child(even) {
      background-color: var(--color-gray-dark-alt);
    }
  }

  .custom__menu-list.custom__menu-list {
    padding: 0;
  }
}
